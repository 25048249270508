<template>
  <div class="login-bg">
    <div class="screen-height">
      <div class="login-content">
        <div class="left-content"></div>
        <el-card class="login-box">
          <div style="width: 100%" class="logoUrl-top">
            <span>速次方{{ logodataurl.titleName }}</span>
          </div>
          <el-tabs
            v-model="activeName"
            stretch
            type="card"
            class="demo-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane label="账号密码" name="first">
              <el-form>
                <el-form-item>
                  <el-input
                    v-model="form.account"
                    size="large"
                    placeholder="请输入手机号或邮箱"
                    clearable
                  >
                    <template #prefix>
                      <el-icon class="font-medium el-input__icon">
                        <User />
                      </el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="form.password"
                    size="large"
                    show-password
                    placeholder="请输入密码"
                  >
                    <template #prefix>
                      <el-icon class="font-medium el-input__icon">
                        <Lock />
                      </el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <!-- <el-form-item>
              <div class="flex-center-between" style="flex: 1">
                <el-checkbox class="text-gray" v-model="checked2" label="记住账号" />
                <el-button type="info" link @click="goResetPassword">忘记密码</el-button>
              </div>
            </el-form-item> -->
                <el-form-item style="text-align: center">
                  <el-button style="width: 100%" type="primary" size="large" @click="userLogin"
                    >登录</el-button
                  >
                </el-form-item>
                <div style="width: 100%" class="logoUrl-bottom">
                  <img :src="logodataurl.logoUrl" class="logoUrl-image" />
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="微信扫码登录" name="second">
              <div class="flex flex-items-center" id="wchartQrcode">
                <img style="width: 100%" v-if="qrCodeImg" :src="qrCodeImg" />
                <div v-else>加载中...</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="手机验证码登录" name="third">
              <el-form>
                <el-form-item>
                  <el-input
                    v-model="smsLoginForm.mobile"
                    size="large"
                    placeholder="请输入手机号"
                    clearable
                  >
                    <template #prefix>
                      <el-icon class="font-medium el-input__icon">
                        <Cellphone />
                      </el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <div class="flex flex-items-center">
                    <el-input
                      v-model="smsLoginForm.code"
                      auto-complete="off"
                      placeholder="验证码"
                      @keyup.enter="handleLoginSms"
                    >
                      <template #prefix>
                        <el-icon class="font-medium el-input__icon">
                          <Lock />
                        </el-icon>
                      </template>
                    </el-input>
                    <el-button
                      style="margin-left: 10px"
                      :loading="isLoadingSms"
                      :disabled="smsNum > 0"
                      type="primary"
                      @click="getSmsCode"
                      >{{ smsNum > 0 ? `${smsNum}S` : '获取验证码' }}</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item style="width: 100%">
                  <el-button
                    :loading="isLoginSms"
                    type="primary"
                    size="large"
                    style="width: 100%"
                    @click="handleLoginSms"
                  >
                    登 录
                  </el-button>
                  <div style="width: 100%" class="logoUrl-bottom">
                    <img :src="logodataurl.logoUrl" class="logoUrl-image" />
                  </div>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
      <div
        style="
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: 10px;
          color: #fff;
          background: rgba(0, 0, 0, 0.3);
        "
      >
        <div
          class="flex flex-items-center flex-content-between"
          style="width: 80%; min-width: 800px; margin: 0 auto"
        >
          <div>
            <!-- <span>Copyright © 2023 </span>速次方科技有限公司<span>. All rights reserved.</span> -->
            <span>北京速次方网络科技有限责任公司</span>
          </div>
          <div class="flex flex-items-center">
            <div>
              <el-link href="https://beian.miit.gov.cn/" target="_blank">{{
                logoData.icp
              }}</el-link>
            </div>
            <img :src="img" style="margin-left: 10px" />
            <div style="margin-left: 10px">
              <el-link
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802043005"
                target="_blank"
                >{{ logodataurl.mpsIcp }}</el-link
              >
            </div>
            <div style="margin-left: 10px">
              <el-link href="https://beian.miit.gov.cn" target="_blank">{{
                logodataurl.icp
              }}</el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog v-model="isPwdDlgVisible" title="提示" width="30%" center>
      <el-alert
        title="您的账号已被重置，请重设置新的密码！"
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
      <el-alert title="密码规则：数字和字母的组合（最少8位）" type="warning" effect="dark" :closable="false"> </el-alert>
      <div>
        <el-input class="mt10" v-model="newPassword" placeholder="请输入新密码"></el-input>
        <el-input class="mt10" v-model="confirmNewPassword" placeholder="请确认密码"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isPwdDlgVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmPassword" :loading="passwordLoading"
            >确定修改</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Lock, User, Cellphone } from '@element-plus/icons-vue'
import request from '@/utils/request'
import img from '@/assets/image/police.jpg'
import { ElMessage } from 'element-plus'
import userApi from '@/api/sys/admin/userApi'
import { useStore } from 'vuex'
export default {
  name: 'Login',
  components: {
    User,
    Lock,
    Cellphone
  },
  data() {
    return {
      img,
      qrCodeImg: '',
      sign: '',
      activeName: 'first',
      logoimge: '',
      logotitle: '',
      newPassword: '',
      confirmNewPassword: '',
      isPwdDlgVisible: false,
      passwordLoading: false,
      logoData: {},
      form: {
        // account: 'admin@wimoor.com',
        // password: '123456'
        account: '',
        password: ''
      },
      isLoginSms: false,
      isLoadingSms: false,
      smsNum: 0,
      smsLoginForm: {
        mobile: '',
        code: ''
      },
      logodataurl: {},
      loginInformation: {}
    }
  },
  methods: {
    handleClick(tab) {
      console.log(tab)
    },
    // 设置session并重定向
    doRedirect(data) {
      const { session } = data
      localStorage.setItem('jsessionid', session)
      localStorage.setItem('userInfo', JSON.stringify(data))
      // let path = sessionStorage.getItem('old_url_before_login')
      // if (path) {
      //   if (path.indexOf('auth/getJSession') > 0 || path == '/' || path == '/login') {
      //     this.$router.push('/home')
      //   } else {
      //     this.$router.push(path)
      //   }
      // } else {
      //   this.$router.push('/home')
      // }
      // this.$router.push('/home')
      //路由跳转判断
      let routingAddress = (() => {
        const roleRoutes = {
          manager: '/erp/sells/fullstatist',
          agent: '/erp/sells/fullstatist/agent',
          shop: '/erp/sells/fullstatist/shop',
          default: '/home'
        }
        return roleRoutes[data.roleType] || roleRoutes['default']
      })()
      this.$router.push(routingAddress)
    },
    // 账号密码登录
    userLogin() {
      if (!this.form.account) {
        ElMessage({
          message: '请输入账号',
          type: 'error'
        })
        return
      }
      if (!this.form.password) {
        ElMessage({
          message: '请输入密码',
          type: 'error'
        })
        return
      }
      request.post('/auth/login', this.form).then((res) => {
        if (res.data) {
          this.loginInformation = res.data
          if (res.data.active) {
            this.$message({
              type: 'success',
              message: '登录成功!'
            })
            this.doRedirect(res.data)
          } else {
            this.form.password = ''
            this.isPwdDlgVisible = true
          }
        } else {
          if (res.msg) {
            this.$message({
              type: 'error',
              message: '登录失败!' + res.msg
            })
          } else {
            this.$message({
              type: 'error',
              message: '登录失败!'
            })
          }
        }
      })
    },
    /**
     * 修改密码提交按钮
     * @param {string} newPassword  新密码
     * @param {string} confirmNewPassword  确认新密码
     */
    confirmPassword() {
      const passwordRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]+$/

      if (this.newPassword !== this.confirmNewPassword) {
        ElMessage.error('两次密码不一致，请重新输入')
        return
      }

      if (this.newPassword.length < 8) {
        ElMessage.error('密码长度必须大于8位，请重新输入')
        return
      }

      if (!passwordRegex.test(this.newPassword)) {
        ElMessage.error('密码格式不正确，请重新输入')
        return
      }

      // 提交
      this.passwordLoading = true
      userApi
        .updatePassword({ account: this.loginInformation.account, password: this.newPassword })
        .then((res) => {
          if (res.data) {
            ElMessage.success('修改成功,请输入新密码登录')
            this.isPwdDlgVisible = false
          }
        })
        .finally(() => {
          this.passwordLoading = false
        })
    },
    // 微信二维码登录
    initWxLogin() {
      request
        .get(`/auth/wechat/login_qr`)
        .then((res) => {
          const { img, sign } = res.data || {}
          this.qrCodeImg = `data:image/png;base64,${img}`
          this.sign = sign
        })
        .finally(() => {})
    },
    checkWxLogin() {
      this.timeout = setTimeout(() => {
        if (this.activeName !== 'second') {
          this.checkWxLogin()
          return
        }
        request.get(`/auth/wechat/login_state?sign=${this.sign}`).then((res) => {
          console.log('res:', res)
          if (res.data || res.data !== '') {
            this.timeout && clearTimeout(this.timeout)
            if (res.data) {
              this.$message({
                type: 'success',
                message: '登录成功!'
              })
              if (res.data.session) {
                this.doRedirect(res.data)
              } else {
                this.$message({
                  type: 'error',
                  message: '没有返回session!'
                })
              }
            } else {
              this.$message({
                type: 'error',
                message: '请先用账号登录后绑定微信!'
              })
            }
          } else {
            this.checkWxLogin()
          }
        })
      }, 2000)
    },

    getSmsCode() {
      if (this.smsNum > 0 || this.isLoadingSms) {
        return
      }
      if (!/^[1][3,4,5,6.7,8,9][0-9]{9}$/.test(this.smsLoginForm.mobile)) {
        ElMessage({
          message: '请输入正确的手机号',
          type: 'error'
        })
        return
      }
      this.isLoadingSms = true

      request
        .get(`/auth/sms_code/${this.smsLoginForm.mobile}`)
        .then((res) => {
          this.smsNum = 60
          this.startTimeout()
        })
        .finally(() => {
          this.isLoadingSms = false
        })
    },
    startTimeout() {
      this.timeOut && clearTimeout(this.timeOut)
      if (this.smsNum <= 0) {
        return
      }
      this.timeOut = setTimeout(() => {
        this.smsNum--
        this.startTimeout()
      }, 1000)
    },
    handleLoginSms() {
      if (!this.smsLoginForm.mobile) {
        ElMessage({
          message: '请输入手机号',
          type: 'error'
        })
        return
      }
      if (!this.smsLoginForm.code) {
        ElMessage({
          message: '请输入验证码',
          type: 'error'
        })
        return
      }
      if (!/^[1][3,4,5,6.7,8,9][0-9]{9}$/.test(this.smsLoginForm.mobile)) {
        ElMessage({
          message: '请输入正确的手机号',
          type: 'error'
        })
        return
      }
      this.isLoginSms = true
      request
        .post('/auth/login', {
          type: 'sms',
          account: this.smsLoginForm.mobile,
          sms_code: this.smsLoginForm.code
        })
        .then((res) => {
          if (res.data) {
            this.$message({
              type: 'success',
              message: '登录成功!'
            })

            this.doRedirect(res.data)
          } else {
            if (res.msg) {
              this.$message({
                type: 'error',
                message: '登录失败!' + res.msg
              })
            } else {
              this.$message({
                type: 'error',
                message: '登录失败!'
              })
            }
          }
        })
        .finally(() => {
          this.isLoginSms = false
        })
    }
  },
  mounted() {
    this.$store.dispatch('fetchGlobalData').then(() => {
      /*获取logo图片、标题*/
      this.logodataurl = this.$store.state.globalData
    })
    let jsessionid = localStorage.getItem('jsessionid')
    if (jsessionid) {
      this.$router.push('/home')
    } else {
      this.initWxLogin()
      this.checkWxLogin()
    }
  },
  beforeUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
}
</script>

<style scoped>
.login-bg {
  background-image: url(../../assets/image/login-bg.png);
  background-size: cover;
}

.screen-height {
  height: calc(100vh);
}

.login-content {
  display: flex;
  justify-content: center;
  padding-top: 10%;
}

.login-box {
  width: 450px;
  padding: 24px;
  border: none;
  background-color: rgba(255, 255, 255);
}

.font-12 {
  font-size: 12px;
}

.left-content {
  width: 36%;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-32 {
  margin-top: 32px;
  justify-content: center;
}

.text-gray {
  color: var(--el-color-info);
}

.demo-tabs .el-tab-pane {
  width: 350px;
  height: 350px;
}

#wchartQrcode {
  width: 350px;
  height: 350px;
  padding: 20px;
}
.logoUrl-top,
.logoUrl-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
}
.logoUrl-top {
  padding-bottom: 10px;
}
.logoUrl-bottom {
  padding-top: 10px;
}
.logoUrl-image {
  width: auto;
  height: 50px;
}
</style>
